<template>
  <div class="home">
  	<Nav />
  	<Breadcrumbs :items="crumbs" />
    <div class="home__main">
      <!-- <img src="https://firebasestorage.googleapis.com/v0/b/mcaliber-bb61e.appspot.com/o/MCaliber%20Lgo_final.png?alt=media&token=605b9197-07ee-49bb-a898-a4641a88ae99" alt="" class="homelogo" />
      <h3>Coming Soon</h3> -->
    	<transition name="fade">
    		<Loader v-if="!products" />
    	</transition>
    	<!-- <div class="ml-3 mb-3 flex">
    		<button class="btn btn__large btn__toggle" v-bind:class="{ btn__dark: surgical }" @click="surgical = true">Surgical</button>
    		<button class="btn btn__large btn__toggle ml-3" @click="surgical = false"  v-bind:class="{ btn__dark: !surgical }">Non-Surgical</button>
    	</div> -->
    	<!-- <transition name="fadeStop">
	    	<ProductsList :products="surgicalProducts" v-if="surgical == true" />
	    </transition> -->
	    <transition name="fadeStop">
	    	<ProductsList :products="products"  />
	    </transition>
    </div>
    <Footer />
  </div>
</template>

<style scoped type="text/css">
  .home__main {
    display: flex;
    align-items: center;
    justify-content: flex-start;
    flex-direction: column;
  }
  .homelogo {
    width:30vw;
  }
</style>

<script>
import { mapState } from 'vuex'
import ProductsList from '@/components/ProductsList.vue'
import Breadcrumbs from '@/components/Breadcrumbs.vue'
import Nav from '@/components/Nav.vue'
import Footer from '@/components/Footer.vue'
import Loader from '@/components/Loader.vue'

export default {
  name: 'home',
  data: () => ({
    surgical: false,
  }),
  computed: {
    ...mapState(['currentUser', 'userProfile', 'products']),
   //  nonSurgicalProducts() {
   //  	return this.products.filter(product => {
			// 	return (product.category != "Surgical N95 Bulk" && product.visible == true)
			// })
   //  },
   //  surgicalProducts() {
   //  	return this.products.filter(product => {
			// 	return (product.category == "Surgical N95 Bulk" && product.visible == true)
			// })
   //  },
    crumbs () {
      let crumbsArray = []
      let step1 = { title: "Catalog", to: false}
      crumbsArray.push(step1)
      return crumbsArray
    },
  },
  components: {
  	Nav,
  	ProductsList,
  	Breadcrumbs,
  	Footer,
  	Loader
  }
}
</script>
